/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        // All sides
        var sides = ["left", "top", "right", "bottom"];

        // Initialize sidebars
        for (var i = 0; i < sides.length; ++i) {
            var cSide = sides[i];
            $(".sidebar." + cSide).sidebar({side: cSide});
        }

        // Click handlers
        $("a[data-action], div[data-action]").on("click", function () {
            var $this = $(this);
            var action = $this.attr("data-action");
            var side = $this.attr("data-side");
            $(".sidebar." + side).css('visibility', 'visible').trigger("sidebar:" + action);

            if(action === 'open') { $(".bg-cover").fadeIn();}
            if(action === 'close') { $(".bg-cover").fadeOut();}

            return false;
        });

       // sticky menu
       $(window).on('resize scroll', function() {
          var height = $(window).scrollTop();

          if(height  > 200) {
              // do something
              $('.stickable').addClass('sticky').css({ top: '0' });
          } else {
              $('.stickable').css({ top: '-100px' }).removeClass('sticky');
       
          }
         });


        //exhibits carousel
        $('.exhibitscarousel').slick({
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          mobileFirst:true,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
              }
            },
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
            ]
        });

  

        $(document).ready(function() {
           $(".exhibitscarousel .item").matchHeight();
        });  

      $(window).on('resize orientationchange', function() {
             $(".exhibitscarousel .item").matchHeight();
        });  

        // expandable accordions
        $("h3[data-expand]").on("click", function() {
          $(this).toggleClass('open').next('.content').slideToggle();
          return false;
        });


        function isEmail(email) {
          var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
          return regex.test(email);
        }

        // email checkbox verification
         $("#mc-embedded-subscribe").on("click", function() {

            $email = $('#email').val();

            if(isEmail($email)) {

              if( $('#checkbox1').is(":checked") ) {
                return true; 
              } else {

                alert('Please indicate that you want to receive email notifications');
                $('#checkbox1').focus();
                return false;

              }

            } else {
                alert('Please verify your email');
                $('#email').focus();
                return false;

            }

           
        });

         /*
         AudioPlayer
         AUTHOR: Osvaldas Valutis, www.osvaldas.info
        */
        (function($, window, document, undefined) {
            var isTouch = 'ontouchstart' in window,
                eStart = isTouch ? 'touchstart' : 'mousedown',
                eMove = isTouch ? 'touchmove' : 'mousemove',
                eEnd = isTouch ? 'touchend' : 'mouseup',
                eCancel = isTouch ? 'touchcancel' : 'mouseup',
                secondsToTime = function(secs) {
                    var hours = Math.floor(secs / 3600),
                        minutes = Math.floor(secs % 3600 / 60),
                        seconds = Math.ceil(secs % 3600 % 60);
                    return (hours == 0 ? '' : hours > 0 && hours.toString().length < 2 ? '0' + hours + ':' : hours + ':') + (minutes.toString().length < 2 ? '0' + minutes : minutes) + ':' + (seconds.toString().length < 2 ? '0' + seconds : seconds);
                },
                canPlayType = function(file) {
                    var audioElement = document.createElement('audio');
                    return !!(audioElement.canPlayType && audioElement.canPlayType('audio/' + file.split('.').pop().toLowerCase() + ';').replace(/no/, ''));
                };

            $.fn.audioPlayer = function(params) {
                var params = $.extend({
                        classPrefix: 'audioplayer',
                        strPlay: '',
                        strPause: '',
                        strVolume: ''
                    }, params),
                    cssClass = {},
                    cssClassSub = {
                        playPause: 'playpause',
                        playing: 'playing',
                        time: 'time',
                        timeCurrent: 'time-current',
                        timeDuration: 'time-duration',
                        bar: 'bar',
                        barLoaded: 'bar-loaded',
                        barPlayed: 'bar-played',
                        volume: 'volume',
                        volumeButton: 'volume-button',
                        volumeAdjust: 'volume-adjust',
                        noVolume: 'novolume',
                        mute: 'mute',
                        mini: 'mini'
                    };

                for (var subName in cssClassSub)
                    cssClass[subName] = params.classPrefix + '-' + cssClassSub[subName];

                this.each(function() {
                    if ($(this).prop('tagName').toLowerCase() != 'audio')
                        return false;

                    var $this = $(this),
                        audioFile = $this.attr('src'),
                        isAutoPlay = $this.get(0).getAttribute('autoplay'),
                        isAutoPlay = isAutoPlay === '' || isAutoPlay === 'autoplay' ? true : false,
                        isLoop = $this.get(0).getAttribute('loop'),
                        isLoop = isLoop === '' || isLoop === 'loop' ? true : false,
                        isSupport = false;

                    if (typeof audioFile === 'undefined') {
                        $this.find('source').each(function() {
                            audioFile = $(this).attr('src');
                            if (typeof audioFile !== 'undefined' && canPlayType(audioFile)) {
                                isSupport = true;
                                return false;
                            }
                        });
                    } else if (canPlayType(audioFile)) isSupport = true;

                    var thePlayer = $('<div class="' + params.classPrefix + '">' + (isSupport ? $('<div>').append($this.eq(0).clone()).html() : '<embed src="' + audioFile + '" width="0" height="0" volume="100" autostart="' + isAutoPlay.toString() + '" loop="' + isLoop.toString() + '" />') + '<div class="' + cssClass.playPause + '" title="' + params.strPlay + '"><a href="#">' + params.strPlay + '</a></div></div>'),
                        theAudio = isSupport ? thePlayer.find('audio') : thePlayer.find('embed'),
                        theAudio = theAudio.get(0);

                    if (isSupport) {
                        thePlayer.find('audio').css({
                            'width': 0,
                            'height': 0,
                            'visibility': 'hidden'
                        });
                        thePlayer.append('<div class="' + cssClass.bar + '"><div class="' + cssClass.barLoaded + '"></div><div class="' + cssClass.barPlayed + '"></div></div><div class="' + cssClass.time + ' ' + cssClass.timeCurrent + '"></div><div class="separator"> - </div><div class="' + cssClass.time + ' ' + cssClass.timeDuration + '"></div><div class="' + cssClass.volume + '"><div class="' + cssClass.volumeButton + '" title="' + params.strVolume + '"><a href="#">' + params.strVolume + '</a></div><div class="' + cssClass.volumeAdjust + '"><div><div></div></div></div></div>');

                        var theBar = thePlayer.find('.' + cssClass.bar),
                            barPlayed = thePlayer.find('.' + cssClass.barPlayed),
                            barLoaded = thePlayer.find('.' + cssClass.barLoaded),
                            timeCurrent = thePlayer.find('.' + cssClass.timeCurrent),
                            timeDuration = thePlayer.find('.' + cssClass.timeDuration),
                            volumeButton = thePlayer.find('.' + cssClass.volumeButton),
                            volumeAdjuster = thePlayer.find('.' + cssClass.volumeAdjust + ' > div'),
                            volumeDefault = 0,
                            adjustCurrentTime = function(e) {
                                theRealEvent = isTouch ? e.originalEvent.touches[0] : e;
                                theAudio.currentTime = Math.round((theAudio.duration * (theRealEvent.pageX - theBar.offset().left)) / theBar.width());
                            },
                            adjustVolume = function(e) {
                                theRealEvent = isTouch ? e.originalEvent.touches[0] : e;
                                theAudio.volume = Math.abs((theRealEvent.pageX - volumeAdjuster.offset().left) / volumeAdjuster.width());
                            },
                            updateLoadBar = setInterval(function() {
                              if (theAudio.buffered.length > 0) {
                                barLoaded.width((theAudio.buffered.end(0) / theAudio.duration) * 100 + '%');
                                if (theAudio.buffered.end(0) >= theAudio.duration)
                                    clearInterval(updateLoadBar);
                              }
                            }, 100);

                        var volumeTestDefault = theAudio.volume,
                            volumeTestValue = theAudio.volume = 0.111;
                        if (Math.round(theAudio.volume * 1000) / 1000 == volumeTestValue) theAudio.volume = volumeTestDefault;
                        else thePlayer.addClass(cssClass.noVolume);

                        timeDuration.html('&hellip;');
                        timeCurrent.text(secondsToTime(0));

                        theAudio.addEventListener('loadeddata', function() {
                            timeDuration.text(secondsToTime(theAudio.duration));
                            volumeAdjuster.find('div').width(theAudio.volume * 100 + '%');
                            volumeDefault = theAudio.volume;
                        });

                        theAudio.addEventListener('timeupdate', function() {
                            timeCurrent.text(secondsToTime(theAudio.currentTime));
                            barPlayed.width((theAudio.currentTime / theAudio.duration) * 100 + '%');
                        });

                        theAudio.addEventListener('volumechange', function() {
                            volumeAdjuster.find('div').width(theAudio.volume * 100 + '%');
                            if (theAudio.volume > 0 && thePlayer.hasClass(cssClass.mute)) thePlayer.removeClass(cssClass.mute);
                            if (theAudio.volume <= 0 && !thePlayer.hasClass(cssClass.mute)) thePlayer.addClass(cssClass.mute);
                        });

                        theAudio.addEventListener('ended', function() {
                            thePlayer.removeClass(cssClass.playing);
                        });

                        theBar.on(eStart, function(e) {
                                adjustCurrentTime(e);
                                theBar.on(eMove, function(e) {
                                    adjustCurrentTime(e);
                                });
                            })
                            .on(eCancel, function() {
                                theBar.unbind(eMove);
                            });

                        volumeButton.on('click', function() {
                            if (thePlayer.hasClass(cssClass.mute)) {
                                thePlayer.removeClass(cssClass.mute);
                                theAudio.volume = volumeDefault;
                            } else {
                                thePlayer.addClass(cssClass.mute);
                                volumeDefault = theAudio.volume;
                                theAudio.volume = 0;
                            }
                            return false;
                        });

                        volumeAdjuster.on(eStart, function(e) {
                                adjustVolume(e);
                                volumeAdjuster.on(eMove, function(e) {
                                    adjustVolume(e);
                                });
                            })
                            .on(eCancel, function() {
                                volumeAdjuster.unbind(eMove);
                            });
                    } else thePlayer.addClass(cssClass.mini);

                    if (isAutoPlay) thePlayer.addClass(cssClass.playing);

                    thePlayer.find('.' + cssClass.playPause).on('click', function() {
                        if (thePlayer.hasClass(cssClass.playing)) {
                            $(this).attr('title', params.strPlay).find('a').html(params.strPlay);
                            thePlayer.removeClass(cssClass.playing);
                            isSupport ? theAudio.pause() : theAudio.Stop();
                        } else {
                            $(this).attr('title', params.strPause).find('a').html(params.strPause);
                            thePlayer.addClass(cssClass.playing);
                            isSupport ? theAudio.play() : theAudio.Play();
                        }
                        return false;
                    });

                    $this.replaceWith(thePlayer);
                });
                return this;
            };
        })(jQuery, window, document);





        $( 'audio' ).audioPlayer(); 

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

